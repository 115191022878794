.topbar {
  width: 100%;
  height: 3.375rem;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
}
.topbar .topbar-inner {
  width: 75rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.topbar .topbar-inner .logo {
  height: 100%;
  width: 47px;
}
.topbar .topbar-inner .line {
  margin: 0 .875rem;
  width: 1px;
  height: 1rem;
  background-color: #999999;
}
.topbar .topbar-inner .title {
  font-size: .875rem;
  font-weight: 600;
  color: #333333;
}
