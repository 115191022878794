























































































































































































































































































.register{
    height: 100vh;
    overflow: hidden;
    .main{
        height: calc(100vh - 10.875rem);
        background-image: url("../../assets/humanResources/registerBackground.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
        display: flex;
        justify-content: center;
        align-items: center;
        .register-box{
            height: 27.8125rem;
            width: 54.125rem;
            background: #FFFFFF;
            box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
            border-radius: 8px;
            margin-top: -7.125rem;
            display: flex;
            // 左侧图片
            .image-wrap{
                height: 100%;
                width: 27.8125rem;
                flex-shrink: 0;
                overflow: hidden;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .form-wrap{
                flex-grow: 1;
                flex-shrink: 0;
                padding: 3.75rem;
                box-sizing: border-box;
                .title{
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: #333333;
                    text-align: center;
                }
                // 表单
                /deep/.el-form{
                    margin-top: 1.875rem;
                    .el-input--small{
                        .el-input__inner{
                            height: 2.125rem;
                            line-height: 2.125rem;
                        }
                    }
                    // 验证码的输入框
                    .vcode-wrap{
                        position: relative;
                        .vcode-input{
                            .el-input__inner{
                                padding-right: 7.5rem;
                            }
                            .el-input__suffix{
                                right: 6rem;
                            }
                        }
                        .Vcode{
                            display: flex;
                            align-items: center;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            cursor: pointer;
                            .line{
                                width: 1px;
                                height: 1.25rem;
                                background: #DDDDDD;
                            }
                            .get-code{
                                font-size: .875rem;
                                color: #2878FF;
                                width: 90px;
                                text-align: center;
                            }
                        }
                    }
                    .el-button{
                        border: none;
                        width: 100%;
                        height: 2.125rem;
                        background: #F5F5F5;
                        border-radius: 2px;
                        color:#999999;
                        transition: 0.3s;
                    }
                    .agree{
                        display: flex;
                        align-items: center;
                        font-size: .75rem;
                        color: #999999;
                        .el-checkbox{
                            margin-right: 3px;
                        }
                        span{
                            color: #2878FF;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
