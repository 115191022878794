



















.footbar{
    width: 100%;
    height: 7.5rem;
    background-color: #F5F5F5;
    font-size: .875rem;
    font-weight: 400;
    color: #999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
