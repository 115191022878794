.register {
  height: 100vh;
  overflow: hidden;
}
.register .main {
  height: calc(100vh - 10.875rem);
  background-image: url("../../assets/humanResources/registerBackground.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register .main .register-box {
  height: 27.8125rem;
  width: 54.125rem;
  background: #FFFFFF;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: -7.125rem;
  display: flex;
}
.register .main .register-box .image-wrap {
  height: 100%;
  width: 27.8125rem;
  flex-shrink: 0;
  overflow: hidden;
}
.register .main .register-box .image-wrap img {
  display: block;
  width: 100%;
  height: 100%;
}
.register .main .register-box .form-wrap {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3.75rem;
  box-sizing: border-box;
}
.register .main .register-box .form-wrap .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333333;
  text-align: center;
}
.register .main .register-box .form-wrap /deep/ .el-form {
  margin-top: 1.875rem;
}
.register .main .register-box .form-wrap /deep/ .el-form .el-input--small .el-input__inner {
  height: 2.125rem;
  line-height: 2.125rem;
}
.register .main .register-box .form-wrap /deep/ .el-form .vcode-wrap {
  position: relative;
}
.register .main .register-box .form-wrap /deep/ .el-form .vcode-wrap .vcode-input .el-input__inner {
  padding-right: 7.5rem;
}
.register .main .register-box .form-wrap /deep/ .el-form .vcode-wrap .vcode-input .el-input__suffix {
  right: 6rem;
}
.register .main .register-box .form-wrap /deep/ .el-form .vcode-wrap .Vcode {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.register .main .register-box .form-wrap /deep/ .el-form .vcode-wrap .Vcode .line {
  width: 1px;
  height: 1.25rem;
  background: #DDDDDD;
}
.register .main .register-box .form-wrap /deep/ .el-form .vcode-wrap .Vcode .get-code {
  font-size: .875rem;
  color: #2878FF;
  width: 90px;
  text-align: center;
}
.register .main .register-box .form-wrap /deep/ .el-form .el-button {
  border: none;
  width: 100%;
  height: 2.125rem;
  background: #F5F5F5;
  border-radius: 2px;
  color: #999999;
  transition: 0.3s;
}
.register .main .register-box .form-wrap /deep/ .el-form .agree {
  display: flex;
  align-items: center;
  font-size: .75rem;
  color: #999999;
}
.register .main .register-box .form-wrap /deep/ .el-form .agree .el-checkbox {
  margin-right: 3px;
}
.register .main .register-box .form-wrap /deep/ .el-form .agree span {
  color: #2878FF;
  cursor: pointer;
}
